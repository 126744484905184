$(function()
{
    function formAutomate(e, formm)
    {
        var $this = !e && formm ? formm : this;
        
        var fData = formData($this),
            url = fData.getUrl();
        if(!fData.getBool('isProcess') && !Web.isEmpty(url))
        {
            var frmData = $($this).serializeArray(),
                isValid = formValidators($this),
                status = $($this).find('.status'),
                btn = $($this).find("button[type=submit]:focus"),
                $btn = btn[0] ? btn : $($this).find('button[type="submit"]'),
                form = $this,
                automateBtnLB = $btn.html(),
                errorHandle = handlingInternalErrors("INTERNET_CONNECTION"),
                _callBefore = $($this).data("callbefore") || $($this).data("before"),
                _callAfter = $($this).data("callafter") || $($this).data("after");
            
            
            if(btn.attr("name") && btn.attr("value"))
            {
                frmData[frmData.length] = {name: btn.attr("name"), value: btn.attr("value")};
            }
            //form
            if(errorHandle.status)
            {
                isValid = false;
                status.html(errorHandle.message);
            }
            
            $($this).find('input[type="hidden"]').each(function()
            {
                if($(this).data("required"))
                {
                    if($(this).data("action") && ($(this).val() == "" || $(this).val().length == 0))
                    {
                        var promptAction = window[$(this).data("action")];
                        isValid = false;
                        if(promptAction) promptAction.call(this, $this, formAutoCallback.call(this, $this));
                    }
                }
            });
            
            
            if(isValid)
            {
                if(callFn(_callBefore) != false)
                {
                    status.html("").fadeOut(200);
                    fData.set("isProcess", "true");
                    $btn.html('Wait...').attr('disabled', 'disabled');
                    disableForm(form, true);
                    $.ajax({
                        type: fData.getMethod(),
                        url: url,
                        data: frmData,
                        dataType: "json",
                        success: function (msg)
                        {
                            fData.set("isProcess", "false");
                            disableForm(form);
                            isValid = true;
                            $btn.removeAttr('disabled');
                            $btn.html(automateBtnLB);

                            errorHandle = handlingInternalErrors("DB_SERVER_ERROR", msg);
                            if(errorHandle.status)
                            {
                                status.html(errorHandle.message).fadeIn(200);;
                                isValid = false;
                            }
                            if(isValid)
                            {
                                if(msg)
                                {
                                    if(msg['AlertError']) 
                                    {
                                        msg['msg'] = msg['AlertError'];
                                        msg['status'] = 0;
                                    }
                                    if(msg['AlertSuccess']) 
                                    {
                                        msg['msg'] = msg['AlertSuccess'];
                                        msg['status'] = 1;
                                    }

                                    if(msg['msg'])
                                    {
                                        status.html(bootstrapAlert(msg['msg'], msg['status'])).fadeIn(200);
                                    }
                                    if(msg['_call'])
                                    {
                                        var funName = msg['call'];
                                        if(pageActions[funName]) pageActions[funName].call(this, msg);
                                    }
                                    if(msg['_callback'])
                                    {
                                        callFn(msg['_callback']);
                                    }
                                    if(msg['_clear'])
                                    {
                                        if(msg['clear'] == 'ALL')
                                        {
                                            form.reset();
                                        }
                                    }
                                    if(msg['_reload']) window.location.reload();;
                                    
                                    if(msg['_set'])
                                    {
                                        var dataCollection = msg['set'], setID;
                                        for(setID in dataCollection)
                                        {
                                            if(setID && dataCollection[setID])
                                            {
                                                $("#" + setID).val(dataCollection[setID]);
                                            }
                                        }
                                    }
                                    _actionHanlder(msg);
                                }
                            }
                            callFn(_callAfter);
                        },
                        error: function (errormessage, statusText)
                        {
                            errorHandle = handlingInternalErrors("AJAX_ERROR", statusText);
                            if(errorHandle.status)
                            {
                                status.html(errorHandle.message).fadeIn(200);
                                status.html();
                                fData.set("isProcess", "false");
                                disableForm(form);
                                $btn.removeAttr('disabled');
                                $btn.html(automateBtnLB);
                            }
                            callFn(_callAfter);
                        }
                    });
                }
            }
        }
        if(e) e.preventDefault();
    }
    function formAutoCallback($form)
    {
        return {
            submit: function()
            {
                formAutomate(null, $form);
            }
        };
    }
    $('form.automate input[type="text"]').attr('autocomplete', 'off');
    $(document).on("submit", "form.automate", formAutomate);
    var formData = function(formElement)
    {
        var _ = function() {}, $form = $(formElement);
        
        _.prototype.get = function(name)
        {
            return $form.data(name);
        }
        _.prototype.getBool = function(name)
        {
            return (this.get(name) == 'true')? true : false;
        }
        _.prototype.set = function(name, value)
        {
            $form.data(name, value);
        }
        _.prototype.is = function(name)
        {
            return !Web.isEmpty(this.get(name));
        }
        _.prototype.getUrl = function()
        {
            return $form.attr("action");
        }
        _.prototype.getMethod = function()
        {
            return $form.attr("method");
        }
        return new _();/*function()
        {
            this.get = function(name)
            {
                return $form.data(name);
            }
            this.getBool = function(name)
            {
                return (this.get(name) == 'true')? true : false;
            }
            this.set = function(name, value)
            {
                $form.data(name, value);
            }
            this.is = function(name)
            {
                return !Web.isEmpty(this.get(name));
            }
            this.getUrl = function()
            {
                return $(this).attr("action");
            }
            return this;
        };*/
    }
    
    function delConfirm(title)
    {
        return confirm(title || "Are you sure do you want to delete the item?")
    }
    
    $(document).on("submit", 'form.confirm-go-frm', function(e)
    {
        if(!delConfirm($(this).data("title")))
        {
            e.preventDefault();
        }
    }).on("click","a.xhr-delete-item", function(e)
    {
        var url = $(this).data("url");
        if(!url || url.length < 9)
        {
            e.preventDefault();
            return;
        }
        
        if(delConfirm($(this).data("title")))
        {
            var $this = $(this);
            _actionSpinner($this, true);
            $.ajax({
                type: "post",
                url: url,
                data: {_method: "DELETE"},
                dataType: "json",
                success: function(result)
                {
                    _actionSpinner($this, false);
                    _actionHanlder(result);
                }
            });
        }
        e.preventDefault();
    }).on("click", "a.xhr-request", function(e)
    {
        var prams = $(this).data(),
            url = prams.url || window.location.href,
            method = prams.method || "get";
        if(prams.url) delete prams.url;
        if(prams.method) delete prams.method;
        
        if(method == "patch")
        {
            prams['_method'] = "PATCH";
            method = "post";
        }
        else if(method == "delete")
        {
            prams['_method'] = "DELETE";
            method = "post";
        }
        var $this = $(this);
        _actionSpinner($this, true);
        $.ajax({
            type: method,
            url: url,
            data: prams,
            dataType: "json",
            success: function(result)
            {
                _actionSpinner($this, false);
                _actionHanlder(result);
            }
        });
        e.preventDefault();
    });
    function _actionHanlder(settings)
    {
        if(settings['_print']) window.open(settings['print'], '_blank');
        if(settings['_reload']) window.location.reload();
        if(settings['_goto']) window.location = settings['_goto'];
    }
    function _actionSpinner($elem, status)
    {
        if(status)
        {
            if($elem.find(".fa")[0]) $elem.find(".fa").addClass('fa-spinner fa-spin');
            else $elem.prepend('<span class="fa fa-spinner fa-spin fa-fw xhr-spin"></span>');
        }
        else
        {
            if($elem.find('.xhr-spin')[0]) $elem.find('.xhr-spin').remove();
            else $elem.find(".fa").removeClass('fa-spinner fa-spin');
        }
    }
    
    var printRequestd = false;
    $(document).on("click", "a.xhr-print", function(e)
    {
        var url = $(this).attr('href');
        if(url && url.length > 1)
        {
            printRequestd = true;
            var $btn = $(this);
            var label = $(this).html();
            
            $(this).html('<span class="fa fa-spinner fa-spin fa-fw xhr-spin"></span>');
            
            $.ajax({
                type: 'get',
                url: url,
                data: {},
                success: function(result)
                {
                    $btn.html(label);
                    printRequestd = false;
                    printPage(result);
                },
                error: function()
                {
                    $btn.html(label);
                    printRequestd = false;
                }
            });
            
        }
        e.preventDefault();
    });
    
    (function()
    {
        var dformat = function(d)
        {
            var ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            var mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
            var da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

            return da + "-" + mo + "-" + ye;
        },
        setDate = function(input)
        {
            var date = "";
            if($(input).val().length > 0)
            {
                date = dformat(new Date($(input).val()));
            }

            $(input)
            .css("color", "rgba(0,0,0,0)")
            .siblings(".datepicker_label")
            .css({
                "text-align":"left",
                 position: "absolute",
                 left: "10px",
                 top:"14px",
                 width: $(input).width()
             })
            .text(date);
        };
        
        var index = 1;
        $('input[type="date"]').each(function()
        {
            var height = $(this).height();
            var id = 'default-date-' + index;
            var fontsize = $(this).css("font-size");
            
            $(this).after($('<div />', {id : id}).css({position : 'relative'}));
            
            //$(this).clone().insertAfter("div#" + id);
            //$('#source').prependTo('#destination');
            
            $(this).prependTo("div#" + id);
            $(this).addClass('default-datepicker');
            $("div#" + id + " input").css({
                'text-indent' : '-500px',
                //'height' : $(this).height(),
                //'width' : $(this).width()
            }).after('<span class="datepicker_label" style="pointer-events: none; line-height: ' + (height - 14) + 'px; font-size: ' + fontsize + '"></span>');
            setDate(this);
            
            /*
            //$(this).parent('div').css({position: "relative"});
            */
            index++;
        });

        $(".default-datepicker").on("change", function()
        {
            setDate(this);
        });
    }).call(this);
});
function printPage(html)
{
    var w = window.open("", "PromoteFirefoxWindowName");
    w.document.write(html);
    w.document.close();
    w.addEventListener('load', function()
    {
        w.window.print();
        w.close();
    }, false);

    w.focus();
}
function printA4TablePage(title, table)
{
    var html = '<html><head><title>' + title + '</title><link rel="stylesheet" href="' + AppData.asset  + 'css/print.min.css"></head><body class="default-a4-page"><h1>' + title + '</h1>' + table + '</body></html>';
    printPage(html);
}
var initFormValidation = false;
function formValidators(form, options)
{
    var cls, val, isValid = true, name;
    $(form).find('input,select,textarea').each(function()
    {
        cls = $(this).attr('class');
        name = $(this).attr('name');
        val = $(this).val();
        if(cls && cls.match(/required/g) && val == "")
        {
            $(this).addClass('empty');
            isValid = false;
        }
    });
	
    if(!initFormValidation)
    {
        initFormValidation = true;
        $(".empty").on('change', function()
        {
            $(this).removeClass('empty');
        })
    }
    return isValid;
}
function disableForm(form, option)
{
    if(!form) return ;
    var elem, type, defaultOption = true, d = 'disabled';
    if(!option) defaultOption = false;

    $(form).find('input[type="text"],input[type="email"],input[type="password"],select,textarea').each(function()
    {
        elem = $(this);
        type = elem.attr('type');
        if(type != 'hidden')
        {
                if(defaultOption) elem.attr(d, d);
                else elem.removeAttr(d);
        }
    });
}
var pageActions = {};
function handlingInternalErrors(handle, setting)
{
    var errRet = {status:false, message:"OK"};
    if(handle == "INTERNET_CONNECTION")
    {
        if(navigator.onLine === false) return {status:true, message:'<div class="alert alert-danger" role="alert"><strong>Warning!</strong> Could not connect to internet.</div>'};
    }
    else if(handle == "AJAX_ERROR")
    {
        //timeout, error, abort, parsererror
        if(setting == "parsererror") return {status:true, message:'<div class="alert alert-danger" role="alert"><b>Uncaught SyntaxError :</b><br> Please try again!</div>'};
        else return {status:true, message:'<div class="alert alert-danger" role="alert">Please try again!</div>'};
    }
    if(!setting) return errRet;
    if(setting.errorHandle)
    {
        if(handle == "DB_SERVER_ERROR")
        {
            errRet = {status:true, message:'<div class="alert alert-danger" role="alert">' + setting.message + '</div>'};
        }
    }
    return errRet;
}
function bootstrapAlert(msgText, status)
{
    var cls;
    switch(status)
    {
        case 0:
            cls = 'danger';
        break;
        case 1:
            cls = 'success';
        break;
        case 2:
            cls = 'warning';
        break;
        default:
            cls = 'info';
        break;
    }
    return '<div class="alert alert-' + cls + '" role="alert">' + msgText + '</div>';
}
function callFn(fn)
{
    var r = null;
    if(fn && !fn.match(/[^a-z0-9\_]/gi))
    {
        if(fn && window[fn]) r = window[fn].call(this);
        else eval("if(" + fn + " != 'undefined') r = " + fn + ".call(this, msg);");
    }
    return r;
}

(function()
{
    $(document).ready(function()
    {
        $(document.body).append('<div class="modal fade" id="bs-modal-1"><form class="modal-dialog automate"><div class="modal-content"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button><h4 class="modal-title"></h4></div><div class="modal-body"></div><div class="modal-footer"></div></div></form></div>');
    });
    window.modelWindow = model();
    function model()
    {
        var xhr, isSent, conData = {}, $m;
        $(document).on("click", ".mw-confirm-button", function()
        {
            isSent = true;
            $m.find(".modal-footer").hide();
            $m.find(".modal-body").html("Please wait...");
            xhr = $.post(JSON_URL, conData, function(data)
            {
                isSent = false;
                if(data && data["msg"] && data["status"] == "1")
                {
                    $m.find(".modal-body").html(bootstrapAlert(data["msg"], data["status"]));
                    if(data["goto"]) window.location = data["goto"];
                }
                else
                {
                    $m.find(".modal-footer").show();
                }
            });
        });
        
        return {
            open : function(options)
            {
                $m = $('#bs-modal-1');
                if(options.title) $m.find(".modal-title").html(options.title);
                $m.find(".modal-footer").css({display: "none"}).html("");
                $m.find(".modal-body").html('<div><div class="progress"><div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100" style="width: 100%"><span class="sr-only"></span>Please wait...</div></div></div>');
                if(options.width) $m.find(".modal-dialog").css({width: options.width, margin: "10px auto"});
                if(options.submit)
                {
                    var submit = '<button type="submit" class="btn btn-primary">Save changes</button>',
                        close = ' <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>';
                    if(options.close) submit = close + submit;
                    $m.find(".modal-footer").html(submit);
                }
                
                $m.modal("show");
                if(options.url)
                {
                    var data = {};
                    if(options.query) data = options.query;
                    
                    xhr = $.get(options.url, data, function(data)
                    {
                        if(data.modal)
                        {
                            $m.find(".modal-body").html(data.modal);
                            $m.find(".modal-footer").css({display: "block"});
                        }
                    });
                    $m.on('hide.bs.modal', function(e)
                    {
                        xhr && xhr.abort();
                    });
                }
            },
            confirm: function(options)
            {
                isSent = false;
                $m = $('#bs-modal-1');
                $m.find(".modal-title").html("Confrmation");
                if(options.content) $m.find(".modal-body").html(options.content);
                if(options.width) $m.find(".modal-dialog").css({width: options.width, margin: "10px auto"});
                
                var submit = '<button type="button" class="btn btn-success mw-confirm-button">Ok</button>',
                    close = ' <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>';
            
                $m.find(".modal-footer").html(submit + close).show();
                
                $m.modal("show");
                
                conData = {};
                if(options.query) conData = options.query;
                
                $m.on('hide.bs.modal', function(e)
                {
                    return !isSent;
                });
            }
        }
    }
})();
function wordCount(text)
{
    var count = 0,t = text.replace(/\n/g, " ");
    t = t.replace(/^\s/g, "");
    t = t.replace(/\s$/g, "");
    t = t.replace(/\s+$/g, " ");
    if(t != "")
    {
        count = t.split(" ").length;
    }
    return count;
}
function wordSlice(text, length)
{
     var t = text.replace(/\n/g, " |x|"), arr;
     
        t = t.replace(/^\s/g, "");
        t = t.replace(/\s$/g, "");
        t = t.replace(/\s+$/g, " ");
        newText = "",
        arr = t.split(" ");
    if(arr.length)
    {
        for(var i = 0; i < length; i++)
        {
            if(arr[i]) newText += arr[i] + " ";
        }
        newText = newText.replace(/\s\|x\|/g, "\n");
        newText = newText.replace(/\s$/g, "")
        
    }
    return newText;
}
if(typeof(isEmpty) == "undefined")
{
    function isEmpty(str)
    {
        return str == "" || str.length == 0;
    }
}
function currency(n)
{
    return n == "" ? '0.00' : parseFloat(n).toFixed(2);
}
function URLCompressor(str)
{
    var v = str;
    v = v.toLowerCase();
    v = v.replace(/[\s\,\/\?\:\@\&\=\+\$\#\(\)\'\’\"]/g, "-");
    v = v.replace(/[\-]+/g, "-");
    v = v.replace(/^\-/g, "");
    v = v.replace(/\-$/g, "");
    v = escape(v);
	return v;
}
(function()
{
    var _id = 1, modID = "#notification-module", isRunning = false;
    $(document).ready(function()
    {
        $(document.body).append('<div id="notification-module"></div>');
        $(modID).hide();
    });
    window.notify = model();
    function model()
    {
        var xhr, isSent, conData = {}, $m;
        $(document).on("click", ".mw-confirm-button", function()
        {
            isSent = true;
            $m.find(".modal-footer").hide();
            $m.find(".modal-body").html("Please wait...");
            xhr = $.post(JSON_URL, conData, function(data)
            {
                isSent = false;
                if(data && data["msg"] && data["status"] == "1")
                {
                    $m.find(".modal-body").html(bootstrapAlert(data["msg"], data["status"]));
                    if(data["goto"]) window.location = data["goto"];
                }
                else
                {
                    $m.find(".modal-footer").show();
                }
            });
        });
        window.onbeforeunload = function()
        {
            if(isRunning) return 'Are you sure you want to leave?';
        } 
        return {
            show : function(text)
            {
                var eid = 'notify-' + _id;
                this.id = eid;
                this.message = function(text)
                {
                    isRunning = false;
                    $("#" + this.id).html(text);
                };
                $(modID).prepend('<div class="notify" id="' + eid + '">' + text + '</div>').show();
                $("#" + eid).fadeIn(200);
                _id++;
                isRunning = true;
                return this;
            },
            hide : function(ref)
            {
                $("#" + ref).fadeOut(200, function()
                {
                    if($(modID).html() == "")
                    {
                        $(modID).hide();
                        isRunning = false;
                    }
                });
            },
            ignore: function()
            {
                isRunning = false;
            }
        }
    }
})();

var Web = {
    isEmpty : function(str)
    {
        return str == "" || str == null || str == 'undefined';
    }
}, FindBox = function(options)
{
    var $panel, $form, xhr, isAbort, formUrl, formMethod = "get", frmData = {};
    var _completedFunc = null;
    this.go = function(opt)
    {
        fetchForm();
        wait();
        xhr = $.ajax({
            url : (opt && opt.url ? opt.url : formUrl),
            type : formMethod,
            dataType: "json",
            data : frmData,
            error : retry,
            success : result
        });
    }
    this.completed = function(completedCallback)
    {
        _completedFunc = completedCallback;
    }
    if(options.form)
    {
        if(options.form[0]) $form = options.form;
    }
    if(options.panel)
    {
        $panel = options.panel;
        var obj = this;
        $(document).on("click", ".xhr-retry", this.go);
        $(document).on("click", ".pagination a", function(e)
        {
            var u = $(this).attr("href");
            if(u) obj.go({url : u});
            e.preventDefault();
        });
    }
    
    var retry = function()
    {
        status('<button class="btn btn-normal xhr-retry"><span class="glyphicon glyphicon-repeat"></span> Click to Retry</button>');
    }, wait = function()
    {
        status('<div class="css-loader">Loading...</div>');
    }, result = function(data)
    {
        if(data)
        {
            var isData = false;
            if(data && data["table"] && data["table"]['rows'].length > 0)
            {
                setTable(data['table'], data["set"], '.table');
                isData = true;
                delete(data["table"]);
            }
            if(data)
            {
                for(var i in data)
                {
                    if(i && i.match(/^(\.|\#)([a-z0-9\-\_]+)$/))
                    {
                        setTable(data[i], data["set" + i], i);
                        isData = true;
                    }
                }
            }
            if(!isData) noResult();
            else if(_completedFunc) _completedFunc(data);
        }
    }, noResult = function()
    {
        status('<p class="text-center">No Record.</p>');
    }, status = function(msg)
    {
        $panel.find(".box-footer").hide();
        $panel.find(".table tbody tr").hide(400, function()
        {
            $(this).remove();
        });
        $panel.find(".box-body").show().html('<div class="status-msg">' + msg + '</div>');
    }, fetchForm = function()
    {
        formUrl = $form.attr("action");
        formMethod = $form.attr("method") == "post" ? "post" : "get";
        frmData = $form.serializeArray();
    }, sprintf = function(format, params)
    {
        var i = -1;
        return format.replace(/\{([\d]+)\}/g, function(match, m)
        {
            i++;
            return params[i] ? params[i] : "";
        });
    }, setTable = function(data, set, selector)
    {
        var i, $table, link,
            rows = data["rows"],
            format = data["format"];
        $table = $panel.find(selector + ":first tbody");
        
        $panel.find(".panel-footer").hide();
        $panel.find(selector).fadeOut();
        $table.html("");
        for(i in rows)
        {
            //alert(selector + " " + sprintf(format, rows[i]));
            $table.append(sprintf(format, rows[i]));
        }
        //alert(selector + "(" + i + ") " + rows.length + " " + $table.html());
        if(set && set["footer"])
        {
            
            $panel.find(".box-footer").show().html(set["footer"]);
        }
        $panel.find(selector).fadeIn(500);
        $panel.find(".box-body").hide();
    };
    
    return this;
};
(function()
{
    var _class = function() { return this;},
        _$btn, _$field, _isShown = false,
        _icon = function(shown)
        {
            return '<span class="glyphicon glyphicon-eye-' + (shown ? "close" : "open") + '" aria-hidden="true"></span>';
        },
        _genPassword = function(len)
        {
            var alphaSmall = "abcdefghijklmnopqrstuvwxyz",
                alphaCaps = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
                numbers = "0123456789",
                symbol = "!@#$^*()+[]{}:;?,.'_+=-",
                chars = alphaSmall + alphaCaps + numbers + symbol,
                i = 0, word = "", max = chars.length;
            
            while(len && i < len)
            {
                word += chars.substr(_randNumber(max), 1);
                i++;
            }
            return word;
        },
        _randNumber = function(max)
        {
            return Math.round(Math.random() * max);
        };
        
    _class.prototype.show = function()
    {
        if(_$btn) _$btn.html(_icon(true));
        if(_$field) _$field.attr("type", "text");
        _isShown = true;
    }
    _class.prototype.hide = function()
    {
        if(_$btn) _$btn.html(_icon(false));
        if(_$field) _$field.attr("type", "password");
        _isShown = false;
    }
    _class.prototype.generate = function()
    {
        this.show();
        var pass = _genPassword(12);
        if(_$field) _$field.val(pass);
        return pass;
    }
    _class.prototype.reset = function()
    {
        this.hide();
        if(_$field)_$field.val("");
    }
    _class.prototype.toggle = function()
    {
        if(_isShown) this.hide();
        else this.show();
    }
    $(document).ready(function()
    {
        _$btn = $("#psw-group button");
        _$field = $("#password");
            
        PassField.reset();
        $("#psw-group button").on("click", function()
        {
            PassField.toggle();
        });
    })
    window["PassField"] = new _class();
}).call(this);

(function()
{
    $(document).ready(function()
    {
        $(document.body).append('<div class="screen-menu-modal" id="screen-menu-modal"><div class="menu-container"><span class="menu-title"></span><div class="menu-items"></div></div></div>');
        $(document).on("click", ".menu-items a", function(e)
        {
            if(_callback) _callback.call(this, e);
            e.preventDefault();
        })
    });
    var model = function() {};
    var _callback = null;
    model.prototype.show = function(title, items, callback)
    {
        var $model = $('#screen-menu-modal');
        var $items = $model.find('.menu-items');
        _callback = callback;
        
        $model.find('.menu-title').html(title);
        $items.html('');
        for(var i in items)
        {
            $items.append($('<a href="' + i + '" />', {}).html(items[i]));
        }
        $('html,body').css('overflow', 'hidden');
        $model.fadeIn("fast");
    }
    model.prototype.hide = function()
    {
        $('#screen-menu-modal').fadeOut("slow");
        $('html,body').css('overflow', 'auto');
    }
    
    window['screenMenuModal'] = new model();
})();